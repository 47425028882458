import { lazy } from "react";

const routes = [
  {
    path: "home",
    component: lazy(() => import("../pages/Home/Home")),
    exact: true,
  },
  {
    path: "process/:threadId",
    component: lazy(() => import("../pages/Chat/Chat")),
    exact: true,
  },
  {
    path: "purchase",
    component: lazy(() => import("../pages/Billing/Plan")),
    exact: true,
  },
  {
    path: "referrals/:userId?",
    component: lazy(() => import("../pages/Referrals")),
    exact: true,
  },
  {
    path: "user/profile",
    component: lazy(() => import("../pages/Profile/Edit")),
    exact: true,
  },
  {
    path: "admin/users",
    component: lazy(() => import("../pages/Admin/Users")),
    exact: true,
    isAdmin: true,
  },
];

export default routes;
