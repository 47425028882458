const themeOverrides = {
  token: {
    colorPrimary: "#FD4702",
  },
  components: {
    Button: {
      // colorPrimary: "#273E52",
    },
    Layout: {
      colorBgHeader: "#021D2C",
    },
  },
};

export default themeOverrides;
