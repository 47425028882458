import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  threads: [],
};

const threadSlice = createSlice({
  name: "thread",
  initialState,
  reducers: {
    getThreads(state) {
      state.loading = true;
    },
    getThreadsSuccess(state, action) {
      state.loading = false;
      state.threads = action.payload.threads;
    },
    getThreadsFailure(state) {
      state.loading = false;
      state.threads = [];
    },
  },
});

export const { getThreads, getThreadsSuccess, getThreadsFailure } =
  threadSlice.actions;
export default threadSlice.reducer;
