import React from 'react';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';

import UserMenu from './partials/UserMenu';

const { Header, Content, Footer } = Layout;

function AuthLayout({
  children
}) {
  return (
    <Layout>
      <Header className="bg-white bg-cover h-[64px] sticky top-0 z-[100] shadow px-2 pr-5">
        <div className='container mx-auto flex items-center justify-between h-full'>
          <div className="demo-logo ml-3">
            <Link to="/home" className="hidden sm:inline">
              {/* <img src="/imgs/logo.png" alt="logo" className="h-[64px] p-3" /> */}
              <h2 className='m-0'>Alice</h2>
            </Link>
            <Link to="/home" className="inline sm:hidden">
              {/* <img src="/imgs/logo-sm.png" alt="logo" className="w-[64px] p-3" /> */}
              <h2 className='m-0'>A</h2>
            </Link>
          </div>
          <div className='flex items-center'>
            {/* <div>
              <Link className='mr-3 sm:mr-5 md:mr-10 text-white drop-shadow-xl text-[13px] sm:text-[15px]' to={'/dashboard'}>Dashboard</Link>
              <Link className='mr-3 sm:mr-5 md:mr-10 text-white drop-shadow-xl text-[13px] sm:text-[15px]' to={'/pixel'}>Add Pixel</Link>
              <Link className='mr-3 sm:mr-5 md:mr-10 text-white drop-shadow text-[13px] sm:text-[15px]' to={'/billings'}>Billing</Link>
            </div> */}
            <UserMenu />
          </div>
        </div>
      </Header>
      <Layout className='min-h-[calc(100vh_-_64px)] bg-gray-100 relative'>
        <Content className='mx-auto w-full'>
          {children}
        </Content>
      </Layout>
      {/* <Footer className='bg-white text-center'>
        Copyright © 2023 Alice.ai
      </Footer> */}
    </Layout >
  )
}

export default AuthLayout;